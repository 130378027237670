import React, { useEffect, useState } from "react"
import axios from "axios"
import MaterialTable from 'react-data-table-component';
import { CircularProgress, Chip, Button } from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

function SidekiqInfo(props) {
  const { data_type: dataType } = props.match.params
  console.log(props);
  const [dataUploads, setDataUploads] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)

  const titleLabel = {
    DataUploadWorker: "Carga de productos",
    "ProductStocks::UploadWorker": "Carga de stock"
  }

  const labelStatus = {
    complete: "Completado",
    working: "En progreso",
    failed: "Fallido",
    queued: "En cola",
    retrying: "Reintentando"
  }

  async function getDataUpload() {
    const params = { page, per_page: perPage }
    const { data } = await axios.get(`/api/v1/data_uploads?data_type=${dataType}`, { params })
    const newData = data.data.filter(r => !!Object.keys(r.sidekiq_info).length)
    setDataUploads(newData)
    setTotal(data.total)
    props.setLoading(false)
  }

  function handlePageChange(e) {
    console.log({ page: e });
    setPage(e)
  }

  function handlePerPageChange(e) {
    setPerPage(e)
  }


  useEffect(() => {
    props.setLoading(true)
    getDataUpload()
  }, [page, perPage])

  useEffect(() => {
    if (dataUploads.length > 0) {
      props.setLoading(false)
    }
  }, [dataUploads])

  const columns = [
    {
      name: 'Tipo',
      selector: 'sidekiq_info.worker',
      sortable: true,
      hide: 'md',
      cell: row => titleLabel[row.sidekiq_info.worker]
    },
    {
      name: 'Usuario',
      selector: 'sidekiq_info.user',
      sortable: true,
      hide: 'md',
    },
    {
      name: 'Archivo',
      selector: "sidekiq_info.filename",
      sortable: true,
      hide: 'md',
    },
    {
      name: 'Estado',
      selector: 'sidekiq_info.status',
      sortable: true,
      hide: 'md',
      cell: row => <Chip color="default" label={labelStatus[row.sidekiq_info.status]} />
    },
    {
      name: 'Inicio de carga',
      selector: 'created_at',
      sortable: true,
      hide: 'md',
      cell: row => (new Date(row.created_at).toLocaleString())
    },
    {
      name: 'Ultima actualización',
      sortable: true,
      hide: 'md',
      cell: row => (new Date(row.sidekiq_info.update_time * 1000).toLocaleString())
    }

  ]

  function SubHeader(props) {
    const { getDataUpload } = props

    return (
      <Button color="primary" onClick={getDataUpload} startIcon={<RefreshIcon />} variant="outlined">Recargar</Button>
    )
  }

  return (
    <React.Fragment>
      <MaterialTable
        title={titleLabel[dataType]}
        columns={columns}
        data={dataUploads}
        progressPending={props.loading}
        progressComponent={<CircularProgress size={75} />}
        pagination
        paginationServer
        fixedHeader
        responsive={true}
        fixedHeaderScrollHeight="500px"
        highlightOnHover={true}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        paginationTotalRows={total}
        striped={true}
        noDataComponent={<h6>No se han encontrado resultados</h6>}
        paginationComponentOptions={{ rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
        subHeader
        subHeaderComponent={<SubHeader getDataUpload={getDataUpload} />}
      />
    </React.Fragment>
  )
}

export default SidekiqInfo
